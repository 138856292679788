:root {
	--white: #fff;
	--light-gray: rgb(222, 226, 230);
	--gray: rgb(85, 85, 85);
	--dark-gray: rgb(45, 45, 45);
	--orange: rgb(254, 127, 31);
	--red: rgb(190, 31, 31);
	--green: rgb(31, 127, 31);
	--blue: #023399;
	--light-blue: rgb(160, 160, 200);
}

body {
	margin: 0;
	font-size: 14pt;
	font-weight: 400;
	font-family: 'Source Sans Pro', 'Segoe UI', 'Roboto', 'Open Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.main-wrapper {
	color: var(--gray);
	overflow: hidden;
}

.text-bold {
	font-weight: bold;;
}

.text-wrap {
	overflow-wrap: anywhere;
}

.text-small {
	font-size: 75%;
}

header {
	color: var(--white);
	background-color: var(--blue);
}

header .text-muted {
	color: var(--light-blue) !important;
}

.header-right-section {
	min-width: 176px;
}

.btn-link,
a, a:visited, a:active {
	color: var(--orange);
	text-decoration: none;
}

.text-orange {
	color: var(--orange);
}

.bg-blue,
.btn-primary {
	background-color: var(--blue) !important;
	border-color: var(--blue);
}

h1, h2, h3, h4 {
	font-weight: 700;
	padding: 0;
	margin: 0;
}

h1 {
	font-size: 180%;
}

h2, h3 {
	color: var(--blue);
}

h4 {
	font-size: 100%;
	font-weight: bold;
}

.m-01 {
	margin: 1px;
}

.alert-box {
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 999999999;
}

.alert-box .bg-danger .toast-body {
	color: pink;
}

.alert-box .bg-success .toast-body {
	color: var(--light-gray);
}

.cursor-pointer {
	cursor: pointer !important;
}

.loading-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.accordion-button {
	font-size: inherit;
}

.accordion-button:focus {
	box-shadow: none;
}

.accordion-button:focus:not(.collapsed) {
	border-bottom: 1px solid var(--light-gray);
}

.accordion-button:not(.collapsed) {
	color: black;
	background-color: initial;
}

.empty-accordion .accordion-button {
	cursor: default;
}

.empty-accordion .accordion-button::after {
	background-image: initial;
}

.empty-accordion .accordion-button:not(.collapsed)::after {
	background-image: initial;
}

.empty-accordion .accordion-button,
.empty-accordion .accordion-button:focus {
	box-shadow: none;
}

.smart-text-box {
	overflow: hidden;
}

.smart-text-box.preformatted {
	overflow-y: auto;
	overflow-x: auto;
}

.filter-bar .form-label {
	margin-bottom: 0;
}

.table th > div {
	padding: 5px 7px;
	margin: 3px 1px;
}

.advanced-table tr.selected td {
	background-color: var(--light-blue);
}

.table-sm,
.advanced-table-sm {
	font-size: 11pt;
}

.table-sm th div {
	padding: 1px 3px;
	margin-right: 0;
}

.feed-content {
	height: 700px;
	position: relative;
}

.feed-content textarea,
.feed-content textarea:focus {
	font-size: 10pt;
	background-color: var(--light-gray);
	color: var(--gray);
	font-family: Monospaced, monospace;
	white-space: pre;
	box-shadow: none;
}
